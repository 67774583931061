import { useEffect, useState, useRef } from "react";
import { generateFileUrl, userRequest } from "../../requestMethods";
import "../../global.css";
import "./highlights.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { formatPricing } from "../../util/helper";
import Container from "@mui/material/Container";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { getProducts } from "../../redux/apiCalls";
import { useCompany } from "../../components/context/CompanyContext";
import { returnFormData, returnValidaVideo } from "../../util/helper";
import VideoImg from "../../components/VideoImg";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import Avatar from "@mui/material/Avatar";

export default function Highlights() {
  const location = useLocation();
  const [home, setHome] = useState({});
  const [files, setFiles] = useState({});
  const [filesIni, setFilesIni] = useState();
  const [videos, setVideos] = useState({});
  const [videoIni, setVideoIni] = useState();
  const [inputs, setInputs] = useState({});
  const [prdsTitle1, setprdsTitle1] = useState([{}]);
  const [prdsTitle2, setprdsTitle2] = useState([{}]);

  const currentUser = useSelector((state) => state.user.currentUser);
  const [checked, setChecked] = useState(true);
  const inputTitle1 = useRef("");
  const inputSubtitle1 = useRef("");
  const inputTitle2 = useRef("");
  const inputSubtitle2 = useRef("");
  const inputRef = useRef();
  const inputRefVideo = useRef();
  const nav = useNavigate();
  const { companyId } = useCompany();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.product.products);

  useEffect(() => {
    getHome();
    getProducts(dispatch, false, companyId);
  }, [dispatch, companyId]);

  useEffect(() => {
    if (home && Object.keys(home).length > 0) {
      setChecked(home.allproducts);
      setFiles(home.banners);
      setVideos(home.videos);

      if (home.banners && home.banners.length > 0) {
        setFilesIni(true);
      } else {
        setFilesIni(false);
      }

      if (home.videos && home.videos.length > 0) {
        setVideoIni(true);
      } else {
        setVideoIni(false);
      }
      selectGrid1();
      selectGrid2();
    }
  }, [home]);

  const [selectionModel1, setSelectionModel1] = useState();
  const [selectionModel2, setSelectionModel2] = useState();

  const selectGrid1 = () => {
    if (home.hightlights) {
      setprdsTitle1(home.hightlights[0].products);
      if (
        home.hightlights[0].products &&
        home.hightlights[0].products !== undefined
      )
        setSelectionModel1(home.hightlights[0].products);
    }
  };
  const selectGrid2 = () => {
    if (home.hightlights) {
      if (home.hightlights.length > 1) {
        setprdsTitle2(home.hightlights[1].products);
        setSelectionModel2(home.hightlights[1].products);
      }
    }
  };
  const getHome = async () => {
    try {
      const res = await userRequest.get(`${companyId}/home/admin`);

      if (Object.keys(res.data).length > 0) {
        setHome(res.data);
      }
    } catch {
      console.log();
    }
  };
  const validadeListProduct = () => {
    if (prdsTitle1.length < 3 || prdsTitle1.length > 8) {
      Swal.fire(
        "Atenção!",
        "A lista de produtos 1 deve ter no mínimo 3 e máximo 8 produtos.",
        "warning"
      );

      return false;
    }
    return true;
  };
  const validadeListProduct2 = () => {
    if (!validateTitle2()) {
      if (prdsTitle2.length < 3 || prdsTitle2.length > 8) {
        Swal.fire(
          "Atenção!",
          "A lista de produtos 2 deve ter no mínimo 3 e máximo 8 produtos.",
          "warning"
        );
        return false;
      }
    }
    return true;
  };
  const validate = () => {
    if (
      inputTitle1.current.value === "" ||
      inputTitle1.current.value === undefined
    ) {
      return false;
    }
    if (
      inputSubtitle1.current.value === "" ||
      inputSubtitle1.current.value === undefined
    ) {
      return false;
    }
    if (!validateTitle2()) {
      return false;
    }
    return true;
  };
  const validateTitle2 = () => {
    if (
      inputSubtitle2.current.value !== "" &&
      (inputTitle2.current.value === "" ||
        inputTitle2.current.value === undefined)
    ) {
      return false;
    }
    return true;
  };

  const cadastrarClick = async (event) => {
    let upload = true;
    if (!filesIni) {
      upload = await handleUpload();
    }
    if (!videoIni) {
      upload = await handleUploadVideo();
    }
    if (upload) {
      if (Object.keys(home).length > 0) {
        if (validadeListProduct()) {
          if (validadeListProduct2()) {
            Update();
          }
        }
      } else {
        if (validate()) {
          if (validadeListProduct()) {
            if (validadeListProduct2()) {
              Inserir();
            }
          }
        } else {
          Swal.fire(
            "Atenção!",
            "Os campos Título, Subtítulo e a lista produtos devem ser preenchidos",
            "warning"
          );
        }
      }
    }
  };
  const Update = async (event) => {
    try {
      const res = await userRequest.put(`${companyId}/home/`, {
        allproducts: checked,
        hightlights: objHilights(),
        lastUserUpdate: currentUser.userId,
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Home do app alterado com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao alterar a home!", "error");
      }
    } catch (error) {
      console.log("Update", error);
    }
  };

  const objHilights = () => {
    let arrayHighlights = [];
    arrayHighlights.push({
      title: inputTitle1.current.value,
      subTitle: inputSubtitle1.current.value,
      products: prdsTitle1,
      sort: 0,
    });

    if (inputTitle2.current.value !== "") {
      arrayHighlights.push({
        title: inputTitle2.current.value,
        subTitle: inputSubtitle2.current.value,
        products: prdsTitle2,
        sort: 1,
      });
    }

    return arrayHighlights;
  };
  const Inserir = async () => {
    try {
      const res = await userRequest.post(`${companyId}/home/`, {
        allproducts: checked,
        hightlights: objHilights(),
        lastUserUpdate: currentUser.userId,
      });
      if (Object.keys(res.data).length > 0) {
        Swal.fire("Parabéns!", "Home do app inserida com sucesso!", "success");
      } else {
        Swal.fire("Atenção!", "Erro ao inserir a home do app!", "error");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpload = async (event) => {
    if (files) {
      if (files.length > 0) {
        const formData = await returnFormData(files, "banner");

        const res = await userRequest.post(
          `${companyId}/products/images/banners`,
          formData
        );

        if (res.status !== 200) {
          return false;
        }
      }
    }
    return true;
  };
  const handleUploadVideo = async (event) => {
    if (videos) {
      if (videos.length > 0) {
        const formData = await returnFormData(videos, "video");

        const res = await userRequest.post(
          `${companyId}/products/images/videos`,
          formData
        );

        if (res.status !== 200) {
          return false;
        }
      }
    }
    return true;
  };
  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };
  const handleOnDelete = (event) => {
    setFilesIni(false);
    setFiles({});

    const res = userRequest.delete(
      `${companyId}/products/images/deletebanners`
    );

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnDeleteVideo = (event) => {
    setVideoIni(false);
    setVideos({});

    const res = userRequest.delete(`${companyId}/products/images/deletevideos`);

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  };
  const handleOnChange = (event) => {
    if (returnValidaVideo(event.target.files)) {
      setFiles(event.target.files);
    }
  };
  const handleOnChangeVideo = (event) => {
    if (returnValidaVideo(event.target.files)) {
      setVideos(event.target.files);
    }
  };
  const handleOnclick = (event) => {
    event.preventDefault();
    inputRef.current.click();
  };
  const handleOnclickVideo = (event) => {
    event.preventDefault();
    inputRefVideo.current.click();
  };

  const columns = [
    {
      field: "avatar",
      headerName: "Foto",
      headerClassName: "dataGridMuiHeader",
      align: "center",
      headerAlign: "center",
      width: 50,
      display: "flex",
      renderCell: (params) => {
        return (
          <>
            <Avatar src={generateFileUrl(params.value)} />
          </>
        );
      },
    },
    {
      field: "title",
      align: "left",
      headerName: "Produto",
      headerClassName: "dataGridMuiHeader",
      width: 250,
      display: "flex",
      renderCell: (params) => {
        return (
          <div className="productListItem">
            <img className="productListImg" src={params.row.img} alt="" />
            {params.row.title}
          </div>
        );
      },
    },
    {
      field: "ref",
      headerName: "Referência",
      width: 100,
      headerClassName: "dataGridMuiHeader",
      align: "center",
    },
    // {
    //   field: "category",
    //   headerName: "Categoria",
    //   headerClassName: "dataGridMuiHeader",
    //   width: 200,
    //   align: "left",
    // },
    {
      field: "discount",
      headerName: "Preço",
      width: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "price",
      headerName: "Preço com Desconto",
      width: 160,
      align: "center",
      headerAlign: "center",
      type: "number",
      headerClassName: "dataGridMuiHeader",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    // {
    //   field: "discount",
    //   headerName: "Desconto",
    //   width: 200,
    //   headerClassName: "dataGridMuiHeader",
    //   align: "left",
    //   valueFormatter: (params) => formatPricing(params?.value),
    // },
  ];
  const handleChangeChecked = (e) => {
    setChecked(e.target.checked);
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <div className="div_custom_UpdateList">
        <div className="div_custom_TitleContainer">
          <span className="div_custom_UpdateTitle">Home App</span>
        </div>
        <div className="div_custom_UpdateItem">
          <label>Banners</label>
        </div>
        <div>
          {files && files.length > 0 ? (
            <div className="uploads">
              {Array.from(files).map((item, index) => (
                <VideoImg
                  filesIni={filesIni}
                  item={item.newName ? item.newName : item}
                  classImg="bannerUpload"
                  classVideo="bannerUpload"
                />
              ))}
              <div>
                <Button
                  variant="outlined"
                  className="custom_mui_action"
                  onClick={handleOnDelete}
                >
                  Excluir Banners
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <input
                type="file"
                multiple
                onChange={handleOnChange}
                hidden
                accept="image/png, image/jpeg, video/mp4"
                ref={inputRef}
              />
              {currentUser.isAdmin ? (
                <Button
                  variant="outlined"
                  className="custom_mui_action"
                  onClick={handleOnclick}
                >
                  Selecione os banners
                </Button>
              ) : (
                false
              )}
            </div>
          )}
        </div>
        <div className="div_custom_UpdateLeft">
          <div className="div_custom_UpdateItem">
            <label>Título</label>
            <input
              type="text"
              className="div_custom_UpdateInput"
              name="title1"
              defaultValue={
                home && home.hightlights ? home.hightlights[0].title : ""
              }
              onChange={handleChange}
              maxlength="60"
              ref={inputTitle1}
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Subtítulo</label>
            <input
              type="text"
              name="substitle1"
              defaultValue={
                home && home.hightlights ? home.hightlights[0].subTitle : ""
              }
              className="div_custom_UpdateInput"
              onChange={handleChange}
              ref={inputSubtitle1}
              maxlength="60"
            />
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 500 }}>
              {products && products.products ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productId}
                  rows={products.products}
                  rowSelectionModel={selectionModel1}
                  onRowSelectionModelChange={(itm) => {
                    setSelectionModel1(itm);
                    /*const selectedIDs = new Set(itm);
                    const select = products.filter((row) =>
                    selectedIDs.has(row.productId)
                  );
                  */
                    setprdsTitle1(itm);
                  }}
                  columns={columns}
                  pagination
                  autoPageSize
                  checkboxSelection
                />
              ) : (
                false
              )}
            </div>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Título 2</label>
            <input
              type="text"
              className="div_custom_UpdateInput"
              name="title2"
              defaultValue={
                home && home.hightlights && home.hightlights.length > 1
                  ? home.hightlights[1].title
                  : ""
              }
              onChange={handleChange}
              ref={inputTitle2}
              maxlength="60"
            />
          </div>
          <div className="div_custom_UpdateItem">
            <label>Subtítulo 2 </label>
            <input
              type="text"
              name="substitle2"
              defaultValue={
                home && home.hightlights && home.hightlights.length > 1
                  ? home.hightlights[1].subTitle
                  : ""
              }
              className="div_custom_UpdateInput"
              onChange={handleChange}
              ref={inputSubtitle2}
              maxlength="60"
            />
          </div>
          <div className="div_custom_UpdateLeft">
            <div style={{ height: 500 }}>
              {products && products.products ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.productId}
                  rows={products.products}
                  rowSelectionModel={selectionModel2}
                  onRowSelectionModelChange={(itm) => {
                    setSelectionModel2(itm);
                    setprdsTitle2(itm);
                  }}
                  columns={columns}
                  pagination
                  autoPageSize
                  checkboxSelection
                />
              ) : (
                false
              )}
            </div>
          </div>
          <div className="div_custom_UpdateItem">
            <label>Vídeo</label>
          </div>
          <div>
            {videos && videos.length > 0 ? (
              <div className="uploads">
                {Array.from(videos).map((item, index) => (
                  <VideoImg
                    filesIni={videoIni}
                    item={item.newName ? item.newName : item}
                    classImg="bannerUpload"
                    classVideo="bannerUpload"
                  />
                ))}
                <div>
                  <Button
                    variant="outlined"
                    className="custom_mui_action"
                    onClick={handleOnDeleteVideo}
                  >
                    Excluir Vídeo
                  </Button>
                </div>
              </div>
            ) : (
              <div>
                <input
                  type="file"
                  multiple
                  onChange={handleOnChangeVideo}
                  hidden
                  accept="video/mp4"
                  ref={inputRefVideo}
                />
                {currentUser.isAdmin ? (
                  <Button
                    variant="outlined"
                    className="custom_mui_action"
                    onClick={handleOnclickVideo}
                  >
                    Selecione o vídeo
                  </Button>
                ) : (
                  false
                )}
              </div>
            )}
          </div>

          {/* <div>
              <input
                className="custom_Checkbox"
                type="checkbox"
                name="checkAllProducts"
                onChange={handleChangeChecked}
                checked={checked}
              />
              <label>Deseja ver todos os produtos na Home do app?</label>
            </div> */}
        </div>
        <div>
          {currentUser.isAdmin ? (
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<SaveIcon />}
              onClick={cadastrarClick}
            >
              Cadastrar
            </Button>
          ) : (
            false
          )}
        </div>
      </div>
    </Container>
  );
}
