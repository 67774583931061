import "./report.css";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethods";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCompany } from "../../components/context/CompanyContext";
import dayjs from "dayjs";
import React from "react";
import { retunrMonth } from "../../util/helper";
import { ptBR as ptBRCore } from "@mui/x-data-grid/locales";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
} from "@mui/x-data-grid-premium";
import { formatPricing } from "../../util/helper";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import cliente from "../../icons/cliente.svg";
import catalogo from "../../icons/catalogo.svg";
import cadastro from "../../icons/cadastro.svg";

export default function CustomerReport() {
  const [register, setRegister] = useState();
  const [customers, setCustomers] = useState();
  const [customersMonth, setCustomersMonth] = useState();
  const [customersStatus, setCustomersStatus] = useState();
  const [topCustomers, setTopCustomers] = useState();
  const [fidelizacaoCustomers, setFidelazicaoCustomers] = useState();
  const [share, setSharedQuantity] = useState();
  const [dataIni, setDateIni] = useState();
  const [dataFim, setDateFim] = useState();
  const currentUser = useSelector((state) => state.user.currentUser);
  const { companyId } = useCompany();
  const [readonly, setReadonly] = useState();
  const [analitico, setAnalitico] = useState();
  const [traffic, setTraffic] = useState();
  const nav = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setReadonly("7");
    if (!currentUser) {
      nav(`../login`);
    }
    if (companyId > 0) {
      getCustomersStatus();
      getCustomersAcess();
      getCustomersRegister();
      getShares();
      getCustomersMonth();
      getTopCustomers();
      getFidelizacaoCustomers();
    }
    setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
    setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
  }, [companyId]);

  useEffect(() => {
    if (companyId > 0) {
      getCustomersAcess();
      getCustomersRegister();
      getShares();
      getTopCustomers();
      getFidelizacaoCustomers();
    }
  }, [dataIni, dataFim]);

  const getTopCustomers = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        limit: 20,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/topCustomers`,
          obj
        );

        setTopCustomers(res.data);
      }
    } catch {}
  };

  const getFidelizacaoCustomers = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        fidelizacao: true,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/topCustomers`,
          obj
        );

        setFidelazicaoCustomers(res.data);
      }
    } catch {}
  };
  const getCustomersAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAcess`,
          obj
        );

        setCustomers(res.data);
      }
    } catch {}
  };
  const getCustomersStatus = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersStatus`
      );
      setCustomersStatus(res.data);
    } catch {}
  };
  const getCustomersMonth = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersmonth`
      );
      setCustomersMonth(
        res.data.map((item) => ({
          criado: retunrMonth(item.createdAt),
          count: item.count,
        }))
      );
    } catch {}
  };
  const getCustomersRegister = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };
      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersRegister`,
          obj
        );

        setRegister(res.data);
      }
    } catch {}
  };

  const getShares = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/share`,
          obj
        );

        setSharedQuantity(res.data);
      }
    } catch {}
  };

  const handleChangeStart = (e) => {
    setDateIni(e.target.value);
  };
  const handleChangeEnd = (e) => {
    setDateFim(e.target.value);
  };
  const handleChange = (event) => {
    setReadonly(event.target.value);
    // eslint-disable-next-line default-case
    switch (event.target.value) {
      case "0":
        setDateIni(dayjs(new Date()).format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "7":
        setDateIni(dayjs(new Date()).add(-7, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "15":
        setDateIni(dayjs(new Date()).add(-15, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
      case "30":
        setDateIni(dayjs(new Date()).add(-30, "day").format("YYYY-MM-DD"));
        setDateFim(dayjs(new Date()).format("YYYY-MM-DD"));
        break;
    }
  };
  const getAnaliticoStatusQtdeCustomer = async () => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersAnaliticoStatus/qtdeCustomer`
      );

      setAnalitico(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStatusAtivos = async (e) => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersAnaliticoStatus/ativos`
      );

      setAnalitico(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStatusInativos = async (e) => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersAnaliticoStatus/inativos`
      );

      setAnalitico(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoStatusReconquista = async (e) => {
    try {
      const res = await userRequest.get(
        `${companyId}/reportCustomer/customersAnaliticoStatus/reconquista`
      );

      setAnalitico(res.data);
      window.scrollTo(0, document.body.scrollHeight);
    } catch {}
  };
  const getAnaliticoRegisterAprovado = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        type: "Aprovado",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoRegister`,
          obj
        );
        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getAnaliticoRegisterPendentes = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        type: "Pendente",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoRegister`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getAnaliticoRegisterNegados = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        type: "Negado",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoRegister`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getAnaliticoRegisterLead = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        type: "Aprovado",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersLead`,
          obj
        );

        setAnalitico(
          res.data.map((item) => ({
            customerId: item.lead,
            contact: item.lead,
          }))
        );
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoAcessTotal = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "totalAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoAcessTrafficTotal = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "totalTrafficAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };

  const getCustomersAnaliticoAcessTrafficPend = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "trafficPend",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };

  const getCustomersAnaliticoAcessAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "acess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );
        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoTrafficAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "trafficAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoAcessNAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "nAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoAcessTrafficNAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "trafficNAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };
  const getCustomersAnaliticoAcesscNotAcess = async () => {
    try {
      let obj = {
        start: dataIni,
        end: dataFim,
        typeAcess: "cNotAcess",
      };

      if (dataIni && dataFim) {
        const res = await userRequest.post(
          `${companyId}/reportCustomer/customersAnaliticoAcess`,
          obj
        );

        setAnalitico(res.data);
        window.scrollTo(0, document.body.scrollHeight);
      }
    } catch {}
  };

  const columnsAnalitico = [
    {
      field: "customerId",
      headerName: "Id",
      headerClassName: "dataGridMuiHeader",
      width: 0,
      align: "left",
    },
    {
      field: "name",
      headerName: "Nome",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contato",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
  ];

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "contact",
      headerName: "Contato",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "quantity",
      headerName: "Qtde de compras",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
    {
      field: "amount",
      headerName: "Total",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
      valueFormatter: (value) => {
        if (value === null) {
          return "";
        }
        return `${formatPricing(value)}`;
      },
    },
    {
      field: "managedBy",
      headerName: "Vendedor(a)",
      headerClassName: "dataGridMuiHeader",
      width: 200,
      align: "left",
    },
  ];
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const GridToolbarExport = ({
    csvOptions,
    printOptions,
    excelOptions,
    ...other
  }) => (
    <GridToolbarExportContainer {...other}>
      <GridCsvExportMenuItem options={csvOptions} />
      <GridExcelExportMenuItem options={excelOptions} />
    </GridToolbarExportContainer>
  );
  const _voltarTopo = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className="div_home_Container">
      <table width="100%" className="tableSpaceTab">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cliente} />
                    </td>
                    <td>
                      <tr className="labelTitle">Status do cliente</tr>
                      <tr className="labelMsgData">
                        Exibindo dados da data atual
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>

            <table className="table_painel">
              <tr>
                <td width="25%" className="status_painel">
                  Total
                </td>
                <td width="25%" className="status_painel">
                  Ativos
                  <span className="labelMsgDataAtivos">(Últimos 90 dias)</span>
                </td>
                <td width="25%" className="status_painel">
                  Inativos
                  <span className="labelMsgDataAtivos">
                    (Compras de 91 a 180 dias)
                  </span>
                </td>
                <td width="25%" className="status_painel">
                  Reconquista
                  <span className="labelMsgDataAtivos">
                    (Compras maior 180 dias)
                  </span>
                </td>
              </tr>
              <tr>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticoStatusQtdeCustomer}
                >
                  {customersStatus && customersStatus.qtdeCustomer
                    ? customersStatus.qtdeCustomer
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticoStatusAtivos}
                >
                  {customersStatus && customersStatus.ativos
                    ? customersStatus.ativos
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticoStatusInativos}
                >
                  {customersStatus && customersStatus.inativos
                    ? customersStatus.inativos
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getAnaliticoStatusReconquista}
                >
                  {customersStatus && customersStatus.reconquista
                    ? customersStatus.reconquista
                    : 0}
                </td>
              </tr>
            </table>
          </div>
        </tr>
      </table>
      <div>
        <div className="datePicker">
          <label className="labelPeriodo">
            Filtrar:
            <input
              type="date"
              id="startDate"
              value={dataIni}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeStart}
              disabled={readonly !== "F"}
            />
            <input
              type="date"
              id="endDate"
              value={dataFim}
              name="trip-start"
              min="2023-01-01"
              onChange={handleChangeEnd}
              disabled={readonly !== "F"}
            />
          </label>
        </div>
        <div className="column">
          <input
            type="radio"
            id="30"
            value="30"
            onChange={handleChange}
            checked={readonly === "30"}
          />
          <label for="30">30 dias</label>
          <input
            type="radio"
            id="15"
            value="15"
            onChange={handleChange}
            checked={readonly === "15"}
          />
          <label for="15">15 dias</label>
          <input
            type="radio"
            id="7"
            value="7"
            onChange={handleChange}
            checked={readonly === "7"}
          />
          <label for="7">7 dias</label>
          <input
            type="radio"
            id="0"
            value="0"
            onChange={handleChange}
            checked={readonly === "0"}
          />
          <label for="0">Hoje</label>
          <input
            type="radio"
            id="0"
            value="F"
            onChange={handleChange}
            checked={readonly === "F"}
          />
          <label for="0">Filtro</label>
        </div>
      </div>
      <table width="100%" className="tableSpaceTab">
        <tr>
          <td width="50%">
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={cadastro} />
                      </td>
                      <td>
                        <tr className="labelTitle">Novos cadastros</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <table
                cellSpacing="1"
                cellPadding="2"
                align="center"
                className="table"
              >
                <tr>
                  <td align="left" className="left">
                    Aprovados
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getAnaliticoRegisterAprovado}
                  >
                    {register && register.aprovado ? register.aprovado : 0}{" "}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Pendentes
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getAnaliticoRegisterPendentes}
                  >
                    {register && register.pendente ? register.pendente : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Recusados
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getAnaliticoRegisterNegados}
                  >
                    {register && register.negado ? register.negado : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Leads
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getAnaliticoRegisterLead}
                  >
                    {register && register.lead ? register.lead : 0}
                  </td>
                </tr>
              </table>
            </div>
          </td>

          <td width="50%">
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={catalogo} />
                      </td>
                      <td>
                        <tr className="labelTitle">Acesso ao catálogo</tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <table
                cellSpacing="1"
                cellPadding="2"
                align="center"
                className="table"
              >
                <tr>
                  <td align="left" className="left">
                    Quantidade de acessos
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getCustomersAnaliticoAcessTotal}
                  >
                    {customers && customers.totalAcess
                      ? customers.totalAcess
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Comprou
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getCustomersAnaliticoAcessAcess}
                  >
                    {customers && customers.acess ? customers.acess : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Não comprou
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getCustomersAnaliticoAcessNAcess}
                  >
                    {customers && customers.nAcess ? customers.nAcess : 0}
                  </td>
                </tr>
                <tr>
                  <td align="left" className="left">
                    Não acessou
                  </td>
                  <td
                    align="right"
                    className="right_black"
                    onClick={getCustomersAnaliticoAcesscNotAcess}
                  >
                    {customers && customers.cNotAcess ? customers.cNotAcess : 0}
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td width="50%">
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={catalogo} />
                      </td>
                      <td>
                        <tr className="labelTitle">
                          Compartilhamento catálogo
                        </tr>
                        <tr className="labelMsgData">
                          {readonly === "F"
                            ? "Exibindo dados do filtro de data"
                            : readonly === "0"
                            ? "Exibindo dados de hoje"
                            : "Exibindo dados dos últimos " +
                              readonly +
                              " dias"}
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={share}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    dataKey="name"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Legend />
                  <Bar
                    dataKey="lido"
                    name="Acessado"
                    stackId="a"
                    fill="#EE8253"
                    barSize={5}
                  />
                  <Bar
                    dataKey="dif"
                    name="Não acessado"
                    stackId="a"
                    fill="#999999"
                    barSize={5}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>

          <td>
            <div className="div_home_custom">
              <table className="table">
                <tr>
                  <td align="left" width="100%">
                    <tr>
                      <td>
                        <img src={catalogo} />
                      </td>
                      <td>
                        <tr className="labelTitle">Histórico de cadastro</tr>
                        <tr className="labelMsgData">
                          Exibindo dados do primeiro dia do ano até hoje
                        </tr>
                      </td>
                    </tr>
                  </td>
                  <td align="left" width="0%">
                    {" "}
                  </td>
                </tr>
              </table>
              <br></br>
              <ResponsiveContainer width="95%" height={250}>
                <BarChart data={customersMonth}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    interval={0}
                    angle={-40}
                    type="category"
                    dataKey="criado"
                    tick={{ fontSize: 12 }}
                  />
                  <YAxis type="number" tick={{ fontSize: 12 }} />
                  <Tooltip />
                  <Bar dataKey="count" fill="#EE8253" barSize={5} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </td>
        </tr>
      </table>
      <table width="100%" className="tableSpaceTab2">
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cadastro} />
                    </td>
                    <td>
                      <tr className="labelTitle">Top 20 clientes</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {topCustomers ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.customerId}
                  rows={topCustomers}
                  disableSelectionOnClick
                  columns={columns}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cadastro} />
                    </td>
                    <td>
                      <tr className="labelTitle">Fidelização clientes</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " +
                            readonly +
                            " dias e menor de 2 compras"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {fidelizacaoCustomers ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.customerId}
                  rows={fidelizacaoCustomers}
                  disableSelectionOnClick
                  columns={columns}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cliente} />
                    </td>
                    <td>
                      <tr className="labelTitle">Tráfego pago</tr>
                      <tr className="labelMsgData">
                        {readonly === "F"
                          ? "Exibindo dados do filtro de data"
                          : readonly === "0"
                          ? "Exibindo dados de hoje"
                          : "Exibindo dados dos últimos " + readonly + " dias"}
                      </tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>

            <table className="table_painel">
              <tr>
                <td width="33%" className="status_painel">
                  Total
                </td>
                <td width="34%" className="status_painel">
                  Pendentes
                </td>
                <td width="33%" className="status_painel">
                  Aprovados
                </td>
                <td width="34%" className="status_painel">
                  Recusados
                </td>
              </tr>
              <tr>
                <td
                  className="labelValue_painel_black"
                  onClick={getCustomersAnaliticoAcessTrafficTotal}
                >
                  {customers && customers.trafficTotal
                    ? customers.trafficTotal
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getCustomersAnaliticoAcessTrafficPend}
                >
                  {customers && customers.trafficPend
                    ? customers.trafficPend
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getCustomersAnaliticoTrafficAcess}
                >
                  {customers && customers.trafficAcess
                    ? customers.trafficAcess
                    : 0}
                </td>
                <td
                  className="labelValue_painel_black"
                  onClick={getCustomersAnaliticoAcessTrafficNAcess}
                >
                  {customers && customers.trafficNAcess
                    ? customers.trafficNAcess
                    : 0}
                </td>
              </tr>
            </table>
          </div>
        </tr>
        <tr>
          <div className="div_home_custom">
            <table className="table">
              <tr>
                <td align="left" width="100%">
                  <tr>
                    <td>
                      <img src={cadastro} />
                    </td>
                    <td>
                      <tr className="labelTitle">Analítico clientes</tr>
                    </td>
                  </tr>
                </td>
                <td align="left" width="0%">
                  {" "}
                </td>
              </tr>
            </table>
            <div style={{ height: 350, width: "100%" }}>
              {analitico ? (
                <DataGridPremium
                  sx={{
                    fontFamily: "Manrope",
                    fontWeight: "500",
                    border: "0px",
                  }}
                  rowHeight={45}
                  localeText={
                    ptBRCore.components.MuiDataGrid.defaultProps.localeText
                  }
                  slots={{ toolbar: CustomToolbar }}
                  slotProps={{
                    panel: {
                      sx: {
                        top: "15px !important",
                        left: "50px !important",
                        position: "fixed !important",
                      },
                    },
                  }}
                  getRowId={(row) => row.customerId + row.contact}
                  rows={analitico}
                  disableSelectionOnClick
                  columns={columnsAnalitico}
                  pageSize={8}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </tr>
      </table>
      <div className="divRodape" onClick={_voltarTopo}>
        voltar ao topo
      </div>
    </div>
  );
}
